import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

const TagsPage = props => {
  const { data } = props
  const { edges } = data.allMarkdownRemark

  const pageLinks = edges.map(({ node }) => (
    <div className="box column is-full" key={node.fields.slug}>
      <Link className="level" to={node.fields.slug}>
        <p className="has-text-primary">{node.frontmatter.title}</p>
        <span className="navbar-item hvr-underline-from-left">
          Mehr lesen →
        </span>
      </Link>
    </div>
  ))
  const tag = props.pageContext.tag
  const title = props.data.site.siteMetadata.title
  const totalCount = props.data.allMarkdownRemark.totalCount
  const tagHeader = `${totalCount} page${
    totalCount === 1 ? '' : 's'
  } tagged “${tag}”`

  return (
    <Layout>
      <Hero title={tag} />
      <section className="section">
        <Helmet title={`${tag} | ${title}`} />
        <div className="content">
          <h1 className="title">{tagHeader}</h1>
          <div className="columns is-multiline">{pageLinks}</div>
          <p>
            <Link to="/tags/">Browse all tags</Link>
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default TagsPage

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, preview: { ne: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
          }
        }
      }
    }
  }
`
